<template>
  <div class="h-100 mr-xl-2 ml-xl-2 d-none d-xl-block" v-if="email">
    <DetailButtons class="d-flex flex-column bg-white z-1 p-3 button-bar" :email="email" :state="state" v-model="mode" @delete="close"></DetailButtons>
    <div class="pt-2 email-view">
      <div class="d-flex flex-column bg-white z-1 p-4 h-100" style="overflow: auto" v-if="mode === 'source'">
        <pre>{{ email.rawEmail }}</pre>
      </div>
      <DetailBody v-else class="d-flex flex-column bg-white z-1 p-4 h-100" style="overflow: auto" :email="email" :state="state" :mode="mode"></DetailBody>
    </div>

    <Modal :classes="{ 'd-xl-none': true }" :show="!!state.emailDigest" fullscreen @hide="close">
      <template v-slot:title>
        <DetailButtons class="d-flex flex-column bg-white p-3 button-bar" :email="email" :state="state" v-model="mode" @delete="close"></DetailButtons>
      </template>
      <template v-slot:body>
        <div class="pt-2 email-view">
          <div class="d-flex flex-column bg-white h-100" style="overflow: auto" v-if="mode === 'source'">
            <pre>{{ email.rawEmail }}</pre>
          </div>
          <DetailBody v-else class="d-flex flex-column bg-white h-100" style="overflow: auto" :email="email" :state="state" :mode="mode"></DetailBody>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import {mailServiceApi} from "@/api/MailServiceApi";
import SWR from "@/api/SWR";
import LoadingButton from "@/components/common/LoadingButton.vue";
import Modal from "@/components/common/Modal.vue";
import {ref} from "vue";
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {rpcClient} from "@/api/Vue3WebsocketClient";
import DetailBody from "@/components/DetailBody.vue";
import DetailButtons from "@/components/DetailButtons.vue";
import {Watch} from "vue-property-decorator";

@Options({
  components: {
    LoadingButton, Modal, DetailBody, DetailButtons
  },
  props: {
    state: Object
  }
})
export default class DetailView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  mode: string = 'rich'

  close() {
    this.state.emailDigest = null
  }

  get email(): Email | null {
    if (this.state.emailDigest) {
      let swr: SWR<Email | null, string> = mailServiceApi.getEmail(this.state.emailDigest)
      let email: Email | null = swr.data
      if (swr.call?.promise) {
        swr.call.promise.catch(e => {
          if (e.data && e.data['exceptionTypeName'].endsWith('NotFoundException')) {
            this.toast.error(this.i18n.$gettext('The requested email does not exist.'))
          } else {
            this.toast.error(this.i18n.$gettext('Failed to load email details.'))
          }
        })
      }
      if (this.state.queryId && email) {
        email.query = this.state.queryId
      }
      return email
    } else {
      return null
    }
  }
}
</script>

<style lang="scss">
.button-bar {
  height: 80px
}
.email-view {
  height: 100%
}
@media screen and (min-width: 1200px) {
  .button-bar {
    height: 80px
  }
  .email-view {
    height: calc(100% - 80px)
  }
}
@media screen and (min-width: 1900px) {
  .button-bar {
    height: 86px
  }
  .email-view {
    height: calc(100% - 86px)
  }
}
</style>
