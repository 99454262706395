
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import Setting from "@/model/Setting";
import {settingsServiceApi} from "@/api/SettingsServiceApi";

@Options({
  props: {
    email: Email,
    state: Object,
    mode: String
  }
})
export default class DetailBody extends Vue {

  email!: Email
  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }
  mode: string = 'rich'

  downloadAttachment(attachment: string) {
    if (this.email && this.email.digest) {
      window.open('/emails/' + encodeURIComponent(this.email.digest) + '/attachments/' + encodeURIComponent(attachment), '_blank')
    }
  }

  get separateRecipients(): boolean {
    let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'DETAIL_ADDRESS_DISPLAY')
    if (setting) {
      return setting.value === 'separate'
    } else {
      return false
    }
  }

  get allRecipients() {
    return this.email ? (this.email.to || []).concat(this.email.cc || []).concat(this.email.bcc || []) : []
  }
}
