import {IRpcNotification, RpcWebSocketClient} from 'rpc-websocket-client'
import {reactive} from "vue"
import User from "@/model/User";
import {userServiceApi} from "@/api/UserServiceApi";

export default class WebsocketClient {

    url: string = (window.location.protocol === 'http:' ? 'ws:' : 'wss:') + '//' + window.location.host + '/websocket'
    client = new RpcWebSocketClient()
    connectPromise: Promise<any | void | undefined> | null = null
    stores = []
    state = reactive({
        connected: false,
        failed: false,
        failCount: 0
    })
    session: { token: string | null, user: User | null } = reactive({
        token: null,
        user: null
    })

    constructor() {

        this.client.configure({
            responseTimeout: 0
        });

        this.client.onNotification.push((data: IRpcNotification) => {
            if (data.method === 'logout') {
                this.logout()
            } else {
                let store: any | undefined = this.stores.find(store => {
                    return typeof Object(store)[data.method] === 'function'
                })
                if (store) {
                    let method: any = Object(store)[data.method]
                    if (typeof method === 'function') {
                        method.bind(store)(...data.params)
                    }
                }
            }
        })

        this.client.onClose(() => {
            this.state.connected = false
            setTimeout(() => {
                if (!this.state.connected) {
                    this.connect().then(() => {
                        userServiceApi._getLoggedInUser().then(data => {
                            this.session.user = Object.assign(new User(), data)
                            localStorage.setItem('session', JSON.stringify(this.session))
                        })
                    })
                }
            }, 3000)
        })

        this.client.onError(e => {

        })

        const sessionJson = localStorage.getItem('session')
        if (sessionJson) try {
            const session = JSON.parse(sessionJson)
            this.session.token = session.token
            this.session.user = session.user
        } catch (ignore) {}

        if (this.session.token) {
            this.connect().then(() => {
                userServiceApi._getLoggedInUser().then(data => {
                    this.session.user = Object.assign(new User(), data)
                    localStorage.setItem('session', JSON.stringify(this.session))
                })
            })
        }
    }

    connect(): Promise<any | void | undefined> {
        if (!this.connectPromise) {
            this.connectPromise = this.client.connect(this.url + this.tokenParameter).then(() => {
                this.state.connected = true
                this.state.failed = false
                this.state.failCount = 0
            }).catch(e => {
                this.state.connected = false
                if (this.state.failCount > 2) {
                    this.state.failed = true
                } else {
                    this.state.failCount++
                }
            }).finally(() => {
                this.connectPromise = null
            })
        }
        return this.connectPromise
    }

    call(method: string, params?: any) {
        if (this.state.connected) {
            return this.client.call(method, params)
        } else {
            return this.connect().then(() => {
                return this.client.call(method, params)
            })
        }
    }

    get tokenParameter() {
        return this.session.token ? ('?X-Auth-Token=' + encodeURIComponent(this.session.token)) : ''
    }
    
    login(username: string, password: string): Promise<void> {
        const formData = new FormData()
        formData.set('username', username)
        formData.set('password', password)
        return fetch(window.location.protocol + '//' + window.location.host + '/login', {
            method: 'POST',
            body: formData,
            redirect: 'manual'
        }).then(response => {
            if (response.ok) {
                this.session.token = response.headers.get('X-Auth-Token')
                if (this.session.token) {
                    document.cookie = 'X-Auth-Token=' + this.session.token + ';path=/;SameSite=Lax'
                }
                this.connect()
            }
            return response.json().catch(() => { //No valid json
                throw {
                    timestamp: Date.now(),
                    status: response.status,
                    message: response.statusText,
                    path: '/login',
                    responseObject: null
                }
            }).then(data => {
                if (response.ok) {
                    return data
                } else {
                    throw data
                }
            })
        }).then(data => {
            this.session.user = Object.assign(new User(), data)
            localStorage.setItem('session', JSON.stringify(this.session))
        })
    }

    logout() {
        localStorage.removeItem('session')
        this.session.token = null
        this.session.user = null
        //@ts-ignore
        this.client.ws.close()
    }
}

export const rpcClient = new WebsocketClient()
