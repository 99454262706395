<template>
  <div>
    <div class="row">
      <div class="col">
        <p class="mb-1 text-dark"><span class="badge badge-light"><translate>From</translate>:</span>&emsp;{{ email.sender }}</p>
        <p v-if="separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>To</translate>:</span>&emsp;{{ (email.to || []).join(', ') }}</p>
        <p v-if="separateRecipients && email.cc && email.cc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Cc</translate>:</span>&emsp;{{ (email.cc || []).join(', ') }}</p>
        <p v-if="separateRecipients && email.bcc && email.bcc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Bcc</translate>:</span>&emsp;{{ (email.bcc || []).join(', ') }}</p>
        <p v-if="!separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>Recipients</translate>:</span>&emsp;{{ allRecipients.join(', ') }}</p>
        <p class="mb-1 text-dark"><span class="badge badge-light"><translate>Subject</translate>:</span>&emsp;{{ email.subject }}
          <!--<span v-if="email.threadId">&nbsp;<a href="javascript:" @click="showThread"><translate>Show Thread</translate></a></span>-->
        </p>
        <p class="mb-1 text-dark"><span class="badge badge-light"><translate>Date</translate>:</span>&emsp;{{ new Date(email.date).toLocaleString('de-DE') }}</p>
        <p class="mb-1"><span class="badge badge-light text-dark"><translate>Checksum</translate>:</span>&emsp;
          <i style="transform: translateY(2px)" v-if="email.digestVerified !== null" class="fa fa-lg" :class="email.digestVerified ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
          <span v-else><translate>Loading email and verifying checksum...</translate></span>
        </p>
      </div>
    </div>
    <hr class="mb-2">
    <div v-if="email.attachment && email.attachment.length > 0" class="mb-4">
      <span class="badge badge-light"><translate>Attachments</translate>:</span>&emsp;
      <button v-for="(a,i) in email.attachment" :key="a + i" class="btn btn-outline-primary p-1 pl-2 pr-2 m-2" @click="downloadAttachment(a)"><i class="fa fa-file mr-2"></i> {{ a }}</button>
      <hr class="mt-2">
    </div>
    <div class="row flex-grow-1">
      <div class="col" v-if="email && email.htmlBodies && email.htmlBodies.length > 0 && (mode === 'rich' || !email.textBodies || email.textBodies.length === 0)">
        <iframe v-for="htmlBody in email.htmlBodies" style="height: 100%; width: 100%" frameBorder="0" :srcdoc="htmlBody" sandbox></iframe>
      </div>
      <div class="col" v-else-if="email && email.textBodies && (email.textBodies.length > 0 || mode === 'plain')">
        <pre v-for="textBody in email.textBodies">{{ textBody }}</pre>
      </div>
      <div class="col" v-else-if="!email || email.digestVerified === null">
        <p key="loading"><translate>Loading email body...</translate></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import Setting from "@/model/Setting";
import {settingsServiceApi} from "@/api/SettingsServiceApi";

@Options({
  props: {
    email: Email,
    state: Object,
    mode: String
  }
})
export default class DetailBody extends Vue {

  email!: Email
  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }
  mode: string = 'rich'

  downloadAttachment(attachment: string) {
    if (this.email && this.email.digest) {
      window.open('/emails/' + encodeURIComponent(this.email.digest) + '/attachments/' + encodeURIComponent(attachment), '_blank')
    }
  }

  get separateRecipients(): boolean {
    let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'DETAIL_ADDRESS_DISPLAY')
    if (setting) {
      return setting.value === 'separate'
    } else {
      return false
    }
  }

  get allRecipients() {
    return this.email ? (this.email.to || []).concat(this.email.cc || []).concat(this.email.bcc || []) : []
  }
}
</script>

<style lang="scss">

</style>
