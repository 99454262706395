import { rpcClient } from "@/api/Vue3WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Email from '@/model/Email';
import Page from '@/model/Page';
import { emailStore } from '@/store/generated/GeneratedEmailStore';

export default class GeneratedMailServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getEmail(digest: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getEmail', rpcParams).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.addOrReplaceEmail(model)
            return model.digest
        })
    }

    _queryEmails(query: string, sort: string, page: number, max: number): Promise<number | boolean | void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('queryEmails', rpcParams).then((data: any) => {
            if (Array.isArray(data.items)) {
                let emails: Email[] = data.items.map((email: any) => Object.assign(new Email(), email))
                emailStore.addOrReplaceEmails(emails)
                if (data.total !== null && data.total !== undefined) {
                    return data.total
                } else if (data.hasMore !== null && data.hasMore !== undefined) {
                    return data.hasMore
                }
            } else return Promise.reject()
        })
    }

    _deleteEmail(digest: string, comment: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteEmail', rpcParams).then(() => {
            emailStore.removeEmail(digest)
        })
    }

    queryEmails(query: string, sort: string, page: number, max: number, refresh?: boolean | number): SWR<Email[], number | boolean | void> {
        const result: SWR<Email[], number | boolean | void> = reactive(new SWR<Email[], number | boolean | void>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, arguments.length - 1).filter(arg => arg !== undefined)
        const callId: string = '_queryEmails' + JSON.stringify(args)
        const cached: Call<number | boolean | void> | undefined = this.cache.get(callId)
        const refreshTime = typeof refresh === 'number' ? refresh : 1000
        if (cached && !cached.ended) {
            result.call = cached
        } else if (!cached || refresh === true || (refresh !== false && (cached.ended || 0) < (Date.now() - refreshTime))) {
            result.call = new Call<number | boolean | void>()
            this.cache.set(callId, result.call)
            result.call.loading = !cached
            result.call.refreshing = !!cached
            result.call.promise = this._queryEmails(query, sort, page, max).catch(e => {
                return Promise.reject(e)
            }).finally(() => {
                if (result.call) {
                    result.call.ended = Date.now()
                    result.call.loading = false
                    result.call.refreshing = false
                }
            })
        }
        let emails: Email[] = [...emailStore.state.emails]
        emails = SortAndFilterUtil.filter(emails, { query: query })
        SortAndFilterUtil.sort(emails, sort)
        if (page !== undefined && page !== null && max !== undefined && max !== null) {
            emails = emails.slice(page * max, (page + 1) * max)
        }
        result.data = emails
        return result
    }

    getEmails(sortBy?: string[] | string, pageStart?: number, pageSize?: number): Email[] {
        let emails: Email[] = [...emailStore.state.emails]
        
        SortAndFilterUtil.sort(emails, sortBy)
        if (pageStart !== undefined && pageStart !== null && pageSize !== undefined && pageSize !== null) {
            emails = emails.slice(pageStart * pageSize, (pageStart + 1) * pageSize)
        }
        return emails
    }

    getEmail(digest: string, refresh?: boolean | number): SWR<Email | null, string> {
        const result: SWR<Email | null, string> = reactive(new SWR<Email | null, string>())
        const callId: string = '_getEmail' + JSON.stringify(digest)
        const cached: Call<string> | undefined = this.cache.get(callId)
        const refreshTime = typeof refresh === 'number' ? refresh : 1000
        if (cached && !cached.ended) {
            result.call = cached
        } else if (!cached || refresh === true || (refresh !== false && (cached.ended || 0) < (Date.now() - refreshTime))) {
            result.call = new Call<string>()
            this.cache.set(callId, result.call)
            result.call.loading = !cached
            result.call.refreshing = !!cached
            result.call.promise = this._getEmail(digest).catch(e => {
                return Promise.reject(e)
            }).finally(() => {
                if (result.call) {
                    result.call.ended = Date.now()
                    result.call.loading = false
                    result.call.refreshing = false
                }
            })
        }
        result.data = emailStore.state.emails.find((email: Email) => {
            return email.digest === digest
        }) || null
        return result
    }
}
