import { rpcClient } from "@/api/Vue3WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import User from '@/model/User';
import Page from '@/model/Page';
import { userStore } from '@/store/generated/GeneratedUserStore';

export default class GeneratedUserServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _changeUserRoles(userName: string, roles: string[]): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('changeUserRoles', rpcParams).then((data: any) => {
            const model = Object.assign(new User(), data)
            userStore.removeUser(userName)
            userStore.addOrReplaceUser(model)
            return model.userName
        })
    }

    _getLoggedInUser(): Promise<User> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getLoggedInUser', rpcParams).then((data: any) => {
            return Object.assign(new User(), data)
        })
    }

    _getUsers(): Promise<string[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUsers', rpcParams).then((data: any) => {
            if (Array.isArray(data)) {
                const users: User[] = data.map(user => Object.assign(new User(), user))
                userStore.replaceUsers(users)
                return users.map(user => user.userName || '')
            } else return Promise.reject()
        })
    }

    getUsers(sortBy?: string[] | string, refresh?: boolean | number): SWR<User[], string[]> {
        const result: SWR<User[], string[]> = reactive(new SWR<User[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, arguments.length - 1).filter(arg => arg !== undefined)
        const callId: string = '_getUsers' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        const refreshTime = typeof refresh === 'number' ? refresh : 1000
        if (cached && !cached.ended) {
            result.call = cached
        } else if (!cached || refresh === true || (refresh !== false && (cached.ended || 0) < (Date.now() - refreshTime))) {
            result.call = new Call<string[]>()
            this.cache.set(callId, result.call)
            result.call.loading = !cached
            result.call.refreshing = !!cached
            result.call.promise = this._getUsers().catch(e => {
                return Promise.reject(e)
            }).finally(() => {
                if (result.call) {
                    result.call.ended = Date.now()
                    result.call.loading = false
                    result.call.refreshing = false
                }
            })
        }
        let users: User[] = [...userStore.state.users]
        
        SortAndFilterUtil.sort(users, sortBy)
        
        result.data = users
        return result
    }

    getUser(userName: string): User | undefined {
        return userStore.state.users.find((user: User) => {
            return user.userName === userName
        })
    }
}
